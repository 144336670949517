jQuery(document).ready(function ($) {

  // automatic speedbump
  // find all external links
    $('a:not([href=""], [href^="http://carrolltonbankprod.lrsws.co/"], [href^="https://carrolltonbankprod.lrsws.co/"], [href^="http://carrolltonbanking.com"], [href^="http://www.carrolltonbanking.com"], [href^="https://carrolltonbanking.com"],  [href*=".carrolltonbanking.com"], [href="https://carrolltonbanking.mymortgage-online.com"],[href^="tel"], [href^="/"], [href^="#"], [class*="no-speedbump"], [id*="url-link"], [id*="close-speedbump"], [class*="close-reveal-modal"],  [href*=".mortgage-application.net"],  [href*="web5.secureinternetbank.com"],  [class*="btn-search"])').addClass('external');

  $('[href^="https://vimeo.com/"]').addClass('external no-redirect');

  $('[href^="mailto:"]').addClass('email-link');

  // remove external class from links without href attribute
  $('a:not([href])').removeClass('external');

  // add the link and title to the continue button
  $('body').on('click', 'a.external', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('no-redirect')) {
      var url = $(this).attr('href');
      var target = $(this).attr('target');
      $('#url-link').attr('href', url).attr('target', target);
      $('#em_url-link').attr('href', url).attr('target', target);

      if ($(this).hasClass('email-link')) {
        $('#email_speedbump').addClass('open');
      } else {
        $('#speedbump').addClass('open');
      }

      if ($(this).hasClass('login-btn')) {
        $('#login_speedbump').addClass('open');
      } else {
        $('#speedbump').addClass('open');
      }
    }
  });
  // close speedbump when clicking acknowledgment button
  $('#speedbump a#close-speedbump, #speedbump a#close-reveal-modal, #email_speedbump a#em_close-speedbump, #email_speedbump a#em_close-reveal-modal, #login_speedbump a#em_close-speedbump, #login_speedbump a#em_close-reveal-modal,  #login_speedbump_mobile a#em_close-speedbump, #login_speedbump_mobile a#em_close-reveal-modal, .close-reveal-modal').on('click', function (e) {
    e.preventDefault();
    $('#speedbump').removeClass('open');
    $('#email_speedbump').removeClass('open');
    $('#login_speedbump').removeClass('open');
    $('#login_speedbump_mobile').removeClass('open');
    $('#video-modal').removeClass('open');
    $("body").removeClass('overlay');
  });

  // close reveal if link is clicked
  $('a:not([id*="close-speedbump"], [id*="close-reveal-modal"], [id*="em_close-speedbump"], [id*="em_close-reveal-modal"])').click(function () {
    $('#speedbump').removeClass('open');
    $('#email_speedbump').removeClass('open');
    $('#login_speedbump').removeClass('open');

  });

});

function openModal() {
  $('body').addClass('overlay');
  $('#video-modal').addClass('open');
}

function closeModal(){
  $('#speedbump').removeClass('open');
  $('#email_speedbump').removeClass('open');
  $('#login_speedbump').removeClass('open');
  $('#login_speedbump_mobile').removeClass('open');
  $('#video-modal').removeClass('open');
  $("body").removeClass('overlay');
}